import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/authentication/auth.service';
import { PlaysService } from 'src/app/shared/services/plays/plays.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  imagePreview: string | ArrayBuffer = '';
  albumForm: UntypedFormGroup;
  tracksFiles: Blob[] = [];
  showing: boolean = true;
  picBlob: any;
  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private play: PlaysService
  ) {
    this.albumForm = this.fb.group({
      name: ['', Validators.required],
      artist: ['', Validators.required],
      type: ['', Validators.required],
      art: ['', Validators.required],
      released: ['', Validators.required],
      uri: new UntypedFormControl(''),
      appleSave: new UntypedFormControl(''),
      tracks: this.fb.array([]),
      showing: [this.showing, Validators.required]
    });
  }

  get tracks(): UntypedFormArray {
    return this.albumForm.get('tracks') as UntypedFormArray;
  }

  addTrack() {
    const track = this.fb.group({
      title: '',
      url: '',
      lyrics: '',
      artists: '',
      comments: [],
      likes: [],
      apple: '',
      uri: ''
    });
    this.tracks.push(track);
  }

  async onArtSelected(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        this.imagePreview = reader.result; // This is the preview URL
      };

      reader.readAsDataURL(file);
      this.picBlob = file;
      // Use the file as a Blob for uploads or further processing
      // file is already a Blob
    }
  }

  onTrackSelected(event: Event, index: number) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      this.tracksFiles[index] = file; // Store the Blob in an array
      // You can create a FileReader instance here if you need to read the file,
      // for example, to get the duration or other metadata
    }
  }

  toCamelCase(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
  }

  uploadArt() {}

  async uploadAlbum() {
    const alb = this.toCamelCase(this.albumForm.value.name);
    for (let i = 0; i < this.tracksFiles.length; i++) {
      const ref = `${this.albumForm.value.artist}/${this.albumForm.value.name}/${this.albumForm.value.tracks[i].title}`;
      this.play.uploadStorage(ref, this.tracksFiles[i]).subscribe({
        next: ({ progress, link }) => {
          console.log('Upload progress:', progress);
          if (progress === 100) {
            this.albumForm.value.tracks[i].url = link;
            console.log('Upload completed! Song URL:', link);
            // Handle completion (e.g., store song URL)
          }
        },
        error: (error) => {
          console.error('Error uploading file:', error);
          // Handle error (e.g., show error message)
        }
      });
    }
    const ref = `${this.albumForm.value.artist}/${this.albumForm.value.name}/artwork`;
    await this.play.uploadStorage(ref, this.picBlob).subscribe({
      next: async ({ progress, link }) => {
        console.log('Upload progress:', progress);
        if (progress === 100) {
          console.log(this.albumForm.value);
          this.albumForm.value.art = link;
          console.log('Upload completed! Song Artwork:', link);
          await this.play.addDisco(this.albumForm.value, alb);
          this.router.navigate(['home']);

          // Handle completion (e.g., store song URL)
        }
      },
      error: (error) => {
        console.error('Error uploading file:', error);
        // Handle error (e.g., show error message)
      }
    });
  }

  ngOnInit(): void {}
}
