<div class="outer">
  <!-- Titling row -->
  <div class="titling-row">
    <span class="title-empty-space">Releases</span>
    <span class="title-play-count">Plays</span>
    <span class="title-unique-listeners">Listeners</span>
  </div>

  <!-- Albums list -->
  <div *ngFor="let album of albums" class="album-container">
    <hr />
    <div class="album-header">
      <!-- Display album art -->
      <img class="album-art" [src]="album.art" [alt]="album.name + ' cover'" />
      <!-- Album title and release date -->
      <div class="album-info">
        <div *ngIf="!album.editing" class="album-title">{{ album.name }}</div>
        <div *ngIf="!album.editing" class="release-date">{{ album.released | date : 'MMM d, y' }}</div>
        <div *ngIf="album.editing">
          <input [(ngModel)]="album.name" placeholder="Album Name" />
          <input [(ngModel)]="album.released" placeholder="Release Date" type="date" />
          <button (click)="saveEdit(album)">Save</button>
          <button (click)="cancelEdit(album)">Cancel</button>
        </div>
        <mat-slide-toggle
          style="margin-right: 1rem"
          [(ngModel)]="album.showing"
          (change)="toggleMode($event, album)"
          class="example-margin"
        >
          {{ album.mode }}
        </mat-slide-toggle>
        <div>
          <button *ngIf="!album.editing" (click)="startEdit(album)">Edit Album</button>
          <button *ngIf="album.editing" (click)="cancelEdit(album)">Cancel Edit</button>
          <button (click)="deleteAlbum(album.name)">Delete Release</button>
        </div>
      </div>
    </div>

    <ul class="track-list">
      <!-- Iterate over tracks within an album, adding index to the track title -->
      <li *ngFor="let track of album.tracks; let i = index" class="track-item">
        <!-- Display index and track name -->
        <!-- <span class="track-index"></span> -->
        <span *ngIf="!track.editing" (click)="openSong(album.art, track.title, album.name)" class="track-name"
          >{{ i + 1 }}. {{ track.title }}</span
        >
        <!-- Display count of plays for the track -->
        <span (click)="openSong(album.art, track.title, album.name)" *ngIf="!album.editing" class="play-count">{{
          track.playCount || 0
        }}</span>
        <!-- Display count of unique listeners for the track -->
        <span (click)="openSong(album.art, track.title, album.name)" *ngIf="!album.editing" class="unique-listeners">{{
          track.uniqueListenerCount || 0
        }}</span>
        <button *ngIf="!track.editing && album.editing" (click)="editTrack(album, track)">Edit Track</button>

        <div *ngIf="track.editing">
          <input [(ngModel)]="track.title" placeholder="Track Title" />
          <button (click)="saveEditTrack(album, i, track)">Save</button>
          <button (click)="cancelEditTrack(track)">Cancel</button>
          <!-- Audio file input -->
          <input type="file" (change)="onFileSelected($event, i)" accept=".m4a" />

          <!-- Progress bar -->
          <div *ngIf="upload">
            <mat-progress-bar mode="determinate" [value]="upload"></mat-progress-bar>
            <p>{{ upload | number : '1.0-0' }}%</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
