import { Injectable } from '@angular/core';
import axios from 'axios';
import { FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable, UploadTaskSnapshot } from 'firebase/storage';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  db: any;
  app: FirebaseApp;
  private storage;
  constructor() {
    this.app = initializeApp(environment.firebaseConfig);
    this.db = getFirestore(this.app);
    this.storage = getStorage(this.app);
  }

  uploadStorage(refPath: string, file: Blob): Observable<{ progress: number; link: string }> {
    const storageRef = ref(this.storage, refPath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Create an observable to track upload progress and return download URL
    return new Observable<{ progress: number; link: string }>((observer) => {
      uploadTask.on(
        'state_changed',
        (snapshot: UploadTaskSnapshot) => {
          // Calculate progress percentage
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          observer.next({ progress, link: '' }); // Emit progress, URL will be updated later
        },
        (error) => {
          observer.error(error); // Handle error
        },
        () => {
          // Upload completed successfully, get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((link) => {
            observer.next({ progress: 100, link: link }); // Update progress with 100% and URL
            observer.complete(); // Complete the observable
          });
        }
      );
    });
  }

  async addUser(form, name) {
    await setDoc(doc(this.db, 'users', name), form);
  }

  async preSaveCampaign() {
    const docRef = doc(getFirestore(getApp('secondary')), 'callables', 'misc');
    const docSnap = await getDoc(docRef);
    const tokens = docSnap.data().refreshTokens;
    for (let token of tokens) {
      axios
        .post('https://tt.apps4artists.uk/backend/auth/spotify/refresh', {
          refreshToken: token,
          type: 'album',
          clientId: '6650e997d9454edbb635bb4435173985',
          albumId: docSnap.data().spotifyPre
        })
        .then(async (response: any) => {
          if ((response.status = 200)) {
            console.log('pre-save reponse', response.data);
          }
        })
        .catch((error) => {
          console.error('Error presaving:', error.message);
          console.log(token);
          // Handle subscription creation error
        });
    }
  }
}
