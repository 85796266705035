<div class="outter">
  <div>
    <h1>Upload your release</h1>
  </div>
  <form [formGroup]="albumForm" (ngSubmit)="uploadAlbum()">
    <div class="section">
      <label>Album Art:</label> <br />
      <input type="file" (change)="onArtSelected($event)" accept="image/*" />
      <img [src]="imagePreview" *ngIf="imagePreview" style="max-width: 50px" />
    </div>
    <div class="section">
      <label>Album Name:</label>
      <input style="margin-bottom: 1rem" formControlName="name" placeholder="Album Name" /> <br />
      <label>Album Artist:</label>
      <input style="margin-bottom: 1rem" formControlName="artist" placeholder="Album Artist" /> <br />
      <label>Spotify URI:</label>
      <input style="margin-bottom: 1rem" formControlName="uri" placeholder="Album Artist" /> <br />
      <label>Apple Music Link:</label>
      <input style="margin-bottom: 1rem" formControlName="appleSave" placeholder="Album Artist" /> <br />

      <select style="margin-right: 3rem" formControlName="type">
        <option value="" disabled selected>Release Type</option>
        <option value="EP">EP</option>
        <option value="Single">Single</option>
        <option value="unreleased EP">Unreleased EP</option>
        <option value="unreleased Single">Unreleased Single</option>
        <option value="topPick">Top Pick</option>
        <option value="liveShow">Live Show</option>
      </select>
      <input formControlName="released" type="date" placeholder="Release Date" /> <br />

      <mat-slide-toggle class="example-margin" formControlName="showing">
        Slide me to put in draft mode
      </mat-slide-toggle>
      <!-- <mat-form-field>
        <input matInput [matDatepicker]="picker" formControlName="released" placeholder="Release Date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field> -->
    </div>

    <!-- other inputs -->
    <div class="section" formArrayName="tracks">
      <div *ngFor="let track of tracks.controls; let i = index" [formGroupName]="i">
        <input formControlName="title" placeholder="Track Title" />
        <input formControlName="artists" placeholder="Track Artists" />
        <input formControlName="uri" placeholder="Spotify URI" />
        <input formControlName="apple" placeholder="Apple Music Link" />
        <textarea formControlName="lyrics" placeholder="Track Lyrics"></textarea>

        <!-- Track file upload input -->
        <input type="file" (change)="onTrackSelected($event, i)" accept="audio/m4a" />
        <h4>(.m4a files ONLY)</h4>
      </div>
    </div>

    <div class="section">
      <button type="submit">Upload Album</button>
      <button type="button" (click)="addTrack()">Add Track</button>
      <h6>Warning: If no release date, it will not show in app</h6>
    </div>
  </form>
</div>
